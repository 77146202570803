import { NMAAHCPropTypes, PageTypes, ThemeContext } from "assets";
import { pillarListingAugmentor } from "atoms";
import { graphql } from "gatsby";
import {
  buildGalleryData,
  convertDirectionNavigation,
  convertHero,
  ThematicCardListing,
} from "molecules";
import { convertComponentList, PageLayout } from "organisms";
import PropTypes from "prop-types";
import React from "react";

import PillarPage from "../pillar/pillar";

const ExhibitPageTemplate = ({ data }) => {
  const exhibitData = pillarListingAugmentor(
    data.craftAPI.entry,
    data.craftAPI.entry.children
  );

  buildGalleryData(exhibitData);
  const children = convertComponentList(exhibitData?.componentList);

  let titleDate = "";

  if (exhibitData?.startDate != null && exhibitData?.endDate != null) {
    const startDate = exhibitData?.startDate.slice(0, 4);
    const endDate = exhibitData?.endDate.slice(0, 4);
    titleDate = `${startDate}\u2013${endDate}`;
  }

  children.unshift(
    convertHero({
      titleDate: titleDate,
      onExhibitPage: true,
      ...exhibitData,
    })
  );

  if (exhibitData.children?.length) {
    const hasPillarListing = exhibitData?.componentList.filter(
      (c) => c.__typename === "CraftAPI_componentList_pillarListing_BlockType"
    );
    if (
      exhibitData.presentationType === NMAAHCPropTypes.EXHIBIT_TYPE_THEMED &&
      !hasPillarListing.length
    ) {
      children.push(
        <ThematicCardListing
          pillars={exhibitData?.children}
          shuffle={exhibitData?.shuffle}
        />
      );
    }
    if (exhibitData.presentationType !== NMAAHCPropTypes.EXHIBIT_TYPE_THEMED) {
      const pillarListingIndex = children?.findIndex(
        (c) =>
          c.props?.__typename ===
          "CraftAPI_componentList_pillarListing_BlockType"
      );
      pillarListingIndex >= 0 &&
        children.splice(
          pillarListingIndex,
          1,
          exhibitData.children.map((pillarData) => (
            <PillarPage
              data={{ craftAPI: { entry: pillarData } }}
              fontType={exhibitData.fontType}
              key={pillarData.id}
              presentationType={exhibitData.presentationType}
              sectionOnly
            />
          ))
        );
    }
  }

  children.push(convertDirectionNavigation(exhibitData));

  return (
    <ThemeContext.Provider
      value={{ theme: exhibitData.theme, fontType: exhibitData.fontType }}
    >
      <PageLayout
        coverImage={exhibitData.coverImage}
        pageType={PageTypes.EXHIBITION}
        presentationType={exhibitData.presentationType}
        shortDescription={exhibitData.shortDescription}
        theme={exhibitData.theme}
        title={exhibitData.title}
      >
        {children}
      </PageLayout>
    </ThemeContext.Provider>
  );
};

ExhibitPageTemplate.propTypes = {
  data: PropTypes.shape({
    craftAPI: PropTypes.shape({
      entry: PropTypes.shape({
        children: PropTypes.arrayOf(PropTypes.shape({})),
        componentList: PropTypes.arrayOf(PropTypes.shape({})),
        coverImage: PropTypes.array,
        endDate: PropTypes.string,
        presentationType: NMAAHCPropTypes.ExhibitType,
        shortDescription: PropTypes.string,
        shuffle: PropTypes.bool,
        startDate: PropTypes.string,
        subtitle: PropTypes.string,
        title: PropTypes.string,
        titleColor: PropTypes.string,
      }),
    }),
  }),
};

export const exhibitQuery = graphql`
  query ($uri: [String], $section: [String] = "exhibit") {
    craftAPI {
      entry(uri: $uri) {
        id
        typeHandle
        title
        uri
        ... on CraftAPI_exhibit_exhibit_Entry {
          backgroundImage {
            url
          }
          fontColor
          titleColor
          presentationType
          shuffle
          audioAsset {
            ...AudioAssetFragment
          }
          componentList {
            ...ComponentListFragment
          }
          coverImageCropStyle
          coverImagePositionOverride
          coverImage {
            ...ImageMetadataFragment
          }
          ...ExhibitImagesFragment
          # Pillars
          children {
            ... on CraftAPI_exhibit_pillar_Entry {
              id
              formattedTitleOverride
              buttonText
              uri
              backgroundImage {
                url
              }
              coverImage {
                ...ImageMetadataFragment
              }
              audioAsset {
                ...AudioAssetFragment
              }
              heroComponent {
                ...VideoHeroFragment
              }
              componentList {
                ...ComponentListFragment
              }
              # Exhibit
              parent {
                id
                sectionId
                title
                uri
                # Sibling Pillars
                children {
                  ...PillarNavigationFragment
                }
              }
              # Chapter
              children {
                ...ChapterNavigationFragment
              }
              heroType
              shortDescription
              longDescription
              ...DirectionNavigationFragment
            }
            title
          }
          endDate
          heroType
          formattedTitleOverride
          titleSize
          fontType
          theme
          id
          longDescription
          shortDescription
          startDate
          subtitle
        }
      }
    }
  }
`;

export default ExhibitPageTemplate;
